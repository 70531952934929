@tailwind base;

.text-lg .text-xl .text-2xl .text-3xl .text-4xl  {
    @apply font-display;
}

@tailwind components;
@tailwind utilities;

/* @import url('https://fonts.googleapis.com/css2?family=Sarabun:ital,wght@0,400;1,200&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Prompt:ital,wght@0,400;1,200&display=swap');

@import url('https://cdn.jsdelivr.net/gh/lazywasabi/thai-web-fonts@6/fonts/NotoSansThaiLooped/NotoSansThaiLooped.css');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap'); 

@media print {

   .print-disable{
        display: none;
   }
   
   
 
   
  }